import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { FaTwitter } from 'react-icons/fa';
import img91 from './assets/images/91.png';
import img92 from './assets/images/92.png';
import img93 from './assets/images/93.png';
import img94 from './assets/images/94.png';
import img95 from './assets/images/95.png';
import img96 from './assets/images/96.png';
import img97 from './assets/images/97.png';
import img98 from './assets/images/98.png';
import img99 from './assets/images/99.png';
import './App.css';

const images = [img91, img92, img93, img94, img95, img96, img97, img98, img99];

function App() {
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((currentImage) => (currentImage + 1) % images.length);
        }, 3000); // Change image every 3000 milliseconds (3 seconds)
        return () => clearInterval(interval);
    }, []);

    return (
        <Router>
            <div className="layout">
                <header className="header">
                    <nav className="menu">
                        <li><Link to="/">Home</Link></li>
                    </nav>
                    <div className="twitter-icon">
                        <a href="https://twitter.com/OrdinalCDB" target="_blank" rel="noopener noreferrer">
                            <FaTwitter size={24} style={{ color: '#1DA1F2' }} />
                        </a>
                    </div>
                </header>
                <main className="content">
                    <hr />
                    <div className="main-container">
                        <div className="image-container">
                            <img src={images[currentImage]} alt={`Cover ${91 + currentImage}`} className="image-slider" />
                        </div>
                        <div className="side-container">
                            <h1>🍆🍑<br/><br/>Crypto Dickbutts<br/><br/>(Ordinals)</h1>
                            <br/><br/>
                            <p>100 Crypto Dickbutts on Bitcoin</p>
                        </div>
                    </div>
                </main>
                <footer className="footer">
                   <hr />
                    ordinalcdb.xyz ©2024
                </footer>
            </div>
        </Router>
    );
}

export default App;
